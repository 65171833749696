'use client';
import { useEffect } from 'react';

import { hasKwankoCookieApprovedAtom } from '@tracking/Atoms/HasKwankoCookieApproved.atom';
import { useAtomValue } from 'jotai';

type Props = {
  isHomepage?: boolean;
};

export const KwankoHomepageScript = ({ isHomepage }: Props) => {
  const hasKwankoCookie = useAtomValue(hasKwankoCookieApprovedAtom);

  useEffect(() => {
    setTimeout(() => {
      if (isHomepage && hasKwankoCookie && window.KWKUniJS !== undefined) {
        KWKUniJS.ptag({
          zone: 'homepage',
        });
      }
    }, 800);
  }, [hasKwankoCookie]);

  return null;
};

KwankoHomepageScript.displayName = 'KwankoHomepageScript';
